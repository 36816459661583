import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/az';
import { getLangDefinition } from './language';
import CONFIG from '../../config';

export const formatNumber = value => {
  if (typeof value === 'undefined') return value;
  const onlyNums = value.replace(/[^\d]/g, '').replace(/^0+/, '0');
  return onlyNums || undefined;
};

export const entitiesFilter = (array = [], searchString) => {
  return array.filter(
    item =>
      !item.name.toString().toLowerCase().indexOf(searchString.toLowerCase())
  );
};

export const getGoogleApiKey = () => {
  const isProductionHost = window?.location?.origin.match(/(bina.az)/g);
  return isProductionHost ? CONFIG.googleApiKey : CONFIG.googleApiKeyStaging;
};

export const formatNumberWithComma = number => {
  if (typeof number === 'undefined') return number;
  return number.toLocaleString('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const isExistAndPositiveInteger = value => value && value > 0;

export const accusative = (word, lng) => {
  if (lng !== 'ru') {
    return word;
  }
  return word.replace(/а$/, 'у');
};

export const transformSlug = slug => {
  if (!slug) {
    return;
  }
  const slugArr = slug.split('/');
  slugArr.shift();
  const result = slugArr.join('/');

  return result;
};

export const convertToNumber = value => {
  if ((typeof value === 'string' && !value.length) || value === undefined) {
    return;
  }
  return +value;
};

export const convertToBoolean = value => {
  if (typeof value !== 'boolean' || !value) {
    return;
  }
  return value;
};

export const getValueFromObject = (
  initialArray = [],
  property,
  returnProperty
) => {
  let result;
  let isExistInFirstLevel;

  if (!initialArray.length) {
    return;
  }

  for (let i = 0; i < initialArray.length; i += 1) {
    isExistInFirstLevel = Object.keys(initialArray[i])
      .map(k => initialArray[i][k])
      .includes(property);

    if (isExistInFirstLevel) {
      return initialArray[i][returnProperty];
    }

    if (initialArray[i].children) {
      result = initialArray[i].children.find(item =>
        Object.keys(item)
          .map(k => item[k])
          .includes(property)
      );

      if (result) {
        return result[returnProperty];
      }
    }
  }

  return result && result[returnProperty];
};

export const checkTodayOrYesterday = (today, date, i18n) =>
  date.isBefore(today) ? i18n.t('common.yesterday') : i18n.t('common.today');

export const getCardTime = (i, i18n) => {
  const now = new Date();
  const todayMidnight = dayjs(now.setHours(0, 0, 0, 0));
  const localeDate = dayjs(i).locale(getLangDefinition(i18n.language));

  if (dayjs(i).isBefore(todayMidnight.subtract(1, 'day'))) {
    return localeDate.format('DD MMMM YYYY');
  }

  return `${checkTodayOrYesterday(
    todayMidnight,
    localeDate,
    i18n
  )} ${localeDate.format('HH:mm')}`;
};

export const formatDate = (i, i18n) => {
  const localeDate = dayjs(i).locale(getLangDefinition(i18n.language));

  return localeDate.format('DD MMMM YYYY, HH:mm');
};

export const toQueryString = (obj, prefix) => {
  const str = [];
  let newPrefix;
  let property;

  Object.keys(obj).forEach(item => {
    newPrefix = prefix ? `${prefix}[]` : item.replace(/[[\]]/g, '');
    property = obj[item];

    str.push(
      typeof property === 'object'
        ? toQueryString(property, newPrefix)
        : `${encodeURI(newPrefix)}=${encodeURIComponent(property)}`
    );
  });

  return str.join('&');
};

export const prettyNumber = number =>
  number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const setRangePrice = (priceMin, priceMax, currency) =>
  `${prettyNumber(priceMin)} \u2012 ${prettyNumber(priceMax)} ${currency}`;

export const getCallCenterPhones = () => {
  const {
    contacts: {
      tel,
      tel_url: telUrl,
      tel_additional: telAdditional,
      tel_additional_url: telAdditionalUrl
    }
  } = CONFIG;

  const phonesArr = [
    { tel, telUrl },
    { tel: telAdditional, telUrl: telAdditionalUrl }
  ];

  return phonesArr.filter(
    (phone, index) =>
      phonesArr.findIndex(item => item.tel === phone.tel) === index
  );
};

export const getFirstPaymentAmount = (price, percent) => {
  return Math.floor((price / 100) * percent);
};

export const isLeasedCategory = id => ['lease', 'sale'].includes(id);

export const snakeToCamelCase = str =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const capitalizeFirstLetter = str =>
  str ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : '';
